import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import PAGE_URLS from 'constants/pageUrls';
import Progress from 'features/common/pageLoader/PageLoader';
import PrivateRoute from './PrivateRoute';
import { STATUS_CODES } from 'constants/common';
import NoInternetRoute from './NoInternetRoute';

const LoginLayout = React.lazy(() => import('layout/LoginLayout'));
const LoginPage = React.lazy(() => import('pages/Login.Page'));
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword.Page'));
const DashboardPage = React.lazy(() => import('pages/Dashboard.Page'));
const NewOfferPage = React.lazy(() => import('pages/NewOffer.Page'));
const ErrorPage = React.lazy(() => import('pages/Error.Page'));
const NewTopUp = React.lazy(() => import('pages/NewTopUp.Page'));
const Reports = React.lazy(() => import('pages/Reports/Reports.Page'));

const Router = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Progress />}>
        <Routes>
          {/* index page */}
          <Route element={<NoInternetRoute />}>
            <Route index element={<Navigate to={PAGE_URLS.HOME} replace />} />

            {/* login pages */}
            <Route element={<LoginLayout />}>
              <Route path={PAGE_URLS.LOGIN} element={<LoginPage />} />
              <Route
                path={PAGE_URLS.FORGOT_PASSWORD}
                element={<ForgotPassword />}
              />
            </Route>

            {/* private pages */}
            <Route element={<PrivateRoute />}>
              <Route path={PAGE_URLS.HOME} element={<DashboardPage />} />
              <Route path={PAGE_URLS.NEW_OFFER} element={<NewOfferPage />} />
              <Route
                path={`${PAGE_URLS.NEW_OFFER}/:offerType/:brandCode`}
                element={<NewTopUp />}
              />
              <Route
                path={`${PAGE_URLS.EDIT_OFFER}/:offerType/:brandCode/:offerCode`}
                element={<NewTopUp />}
              />
              <Route path={PAGE_URLS.REPORTS} element={<Reports />} />
            </Route>
            {/* error pages */}
            <Route
              path={PAGE_URLS.NOT_FOUND}
              element={<ErrorPage status={STATUS_CODES.NOT_FOUND} />}
            />
            <Route
              path={PAGE_URLS.SESSION_EXPIRED}
              element={<ErrorPage status={STATUS_CODES.SESSION_EXPIRED} />}
            />
            <Route
              path={PAGE_URLS.FORBIDDEN}
              element={<ErrorPage status={STATUS_CODES.FORBIDDEN} />}
            />
            <Route
              path={PAGE_URLS.INTERNAL_ERROR}
              element={
                <ErrorPage status={STATUS_CODES.INTERNAL_SERVER_ERROR} />
              }
            />

            <Route
              path="*"
              element={<Navigate to={PAGE_URLS.NOT_FOUND} replace />}
            />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default Router;
