const theme = {
  spacing: 1,
  palette: {
    primary: {
      main: '#f8450b',
    },
    secondary: {
      main: '#f4f4f4',
    },
    error: {
      main: '#f50e00',
    },
    info: {
      main: '#8e8e8e',
    },
    // background: {
    //   default: '#f0f2f5',
    // },
  },
  typography: {
    fontFamily: `"Inter",sans-serif`,
  },
  components: {
    // MuiInputBase: {
    //   styleOverrides: {
    //     root: {
    //       borderColor: 'red',
    //     },
    //   },
    // },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#8e8e8e',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: 2,
        },
        shrink: {
          lineHeight: '1.4375em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // backgroundColor: 'blue',
          borderRadius: 0,
          height: '50px',
          letterSpacing: 'normal',
        },
      },
    },
  },
};

export default theme;
