import AuthContext from 'contexts/UserContext';
import NoInternetPage from 'features/errors/NoInternetPage';
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useSyncLogout from 'utils/customHooks/useSyncLogout';

const NoInternetRoute = () => {
  useSyncLogout();
  const contextData = useContext(AuthContext);
  useEffect(() => {
    const interval = setInterval(() => {
      contextData?.setIsOnline(window.navigator.onLine);
    }, 3000);
    return () => clearInterval(interval);
  }, [window.navigator.onLine]);

  useEffect(() => {
    const handleOnlineEvent = (event: Event) => {
      window.location.reload();
    };

    //check if internet is back
    //only works when internet is back from offline to online
    window.addEventListener('online', handleOnlineEvent);
    return () => {
      window.removeEventListener('online', handleOnlineEvent);
    };
  }, []);

  return (
    <>
      <div className={contextData?.isOnline ? 'd-block' : 'd-none'}>
        <Outlet />
      </div>
      <div className={contextData?.isOnline ? 'd-none' : 'd-block'}>
        <NoInternetPage />
      </div>
    </>
  );
};

export default NoInternetRoute;
