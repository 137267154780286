import {
  setStateBooleanType,
  setStateOptionalStringType,
} from 'interfaces/common.interface';
import { ReactNode, createContext, useState } from 'react';

interface AuthContextData {
  accessToken: string | null;
  setAccessToken: setStateOptionalStringType;
  isOnline: boolean;
  setIsOnline: setStateBooleanType;
}

const AuthContext = createContext<AuthContextData | null>(null);
export default AuthContext;

export const AuthDataProvider = ({ children }: { children: ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  let contextData: AuthContextData = {
    accessToken,
    setAccessToken,
    isOnline,
    setIsOnline,
  };
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
