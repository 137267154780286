import { useEffect } from 'react';
import styles from './PageLoader.module.scss';

export default function PageLoader({
  loaderMessage,
}: {
  loaderMessage?: string;
}) {
  useEffect(() => {
    document.body.classList.add('scroll-lock');

    return () => {
      document.body.classList.remove('scroll-lock');
    };
  }, []);

  return (
    <div className={styles['page-loader']}>
      <img
        className={styles['page-loader__img']}
        src="/assets/loader-plus-offers.gif"
        alt="Page Loading"
        loading="eager"
      />
      <p className={styles['message']}>{loaderMessage}</p>
    </div>
  );
}
