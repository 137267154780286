import './styles/styles.scss';
import Router from 'routes/Router';
import { ThemeProvider, createTheme } from '@mui/material';
import defaulTheme from 'utils/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AuthDataProvider } from 'contexts/UserContext';
import { AppDataProvider } from 'contexts/AppContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {
  const siteTheme = createTheme(defaulTheme);
  const staleTime = 10 * (60 * 1000); // 10 mins
  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: staleTime,
        retry: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <AuthDataProvider>
        <AppDataProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={siteTheme}>
              <Router />
            </ThemeProvider>
          </LocalizationProvider>
        </AppDataProvider>
      </AuthDataProvider>
    </QueryClientProvider>
  );
}

export default App;
