import styles from './CommonErrorPage.module.scss';
import LogoHeader from 'features/common/logoHeader/LogoHeader';
import { Button } from '@mui/material';
import { useContext, useEffect } from 'react';
import AuthContext from 'contexts/UserContext';

const NoInternetPage = () => {
  const contextData = useContext(AuthContext);
  useEffect(() => {
    const handleOnline = (event: Event) => {
      window.location.reload();
    };

    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline); // Clean up the listener
    };
  }, []);
  return (
    <div className="common-container">
      <LogoHeader />
      <div
        className={`${styles['error-page']} ${styles['error-page__no-internet']}`}>
        <div className={styles['error-page__img-wrapper']}>
          <img
            className={styles['error-page__img']}
            src="/assets/no-internet.png"
            width={80}
            alt="no-internet"
          />
        </div>
        <h2 className={styles['error-page__title']}>No Internet Connection</h2>
        <p className={styles['error-page__maintext']}>
          That’s all until you’re back online. Connect to internet or try
          switching to another network.
        </p>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            marginTop: 60,
            marginBottom: 24,
            fontSize: 14,
            fontWeight: 600,
            width: 438,
          }}
          onClick={() => contextData?.setIsOnline(window.navigator.onLine)}>
          Refresh Page
        </Button>
      </div>
    </div>
  );
};

export default NoInternetPage;
