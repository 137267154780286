import {
  OfferDetailInterface,
  setOfferDetailType,
  setStateBooleanType,
  setStateOptionalStringType,
} from 'interfaces/common.interface';
import { ReactNode, createContext, useState } from 'react';

interface AppContextData {
  offerDetailData: OfferDetailInterface | null;
  setOfferDetailData: setOfferDetailType;
  isOfferDetailShowing: boolean;
  setIsOfferDetailShowing: setStateBooleanType;
  offerDetailReference: string | null;
  setOfferDetailReference: setStateOptionalStringType;
  showPasswordResetSuccessToast: boolean;
  setShowPasswordResetSuccessToast: setStateBooleanType;
}

const AppContext = createContext<AppContextData | null>(null);
export default AppContext;

export const AppDataProvider = ({ children }: { children: ReactNode }) => {
  const [offerDetailData, setOfferDetailData] =
    useState<OfferDetailInterface | null>(null);
  const [isOfferDetailShowing, setIsOfferDetailShowing] = useState(false);

  const [offerDetailReference, setOfferDetailReference] = useState<
    string | null
  >(null);
  const [showPasswordResetSuccessToast, setShowPasswordResetSuccessToast] =
    useState(false);
  let contextData: AppContextData = {
    offerDetailData,
    setOfferDetailData,
    isOfferDetailShowing,
    setIsOfferDetailShowing,
    offerDetailReference,
    setOfferDetailReference,
    showPasswordResetSuccessToast,
    setShowPasswordResetSuccessToast,
  };
  return (
    <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
  );
};
