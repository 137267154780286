const useUserOperations = () => {
  const createUser = () => {
    // __iAGSHFAW => __iAmGonnaStayHereForAWhile
    localStorage.setItem(
      `__iAGSHFAW${import.meta.env.VITE_ENVIRONMENT}`,
      JSON.stringify('true'),
    );
  };
  const isLoggedIn = !!localStorage.getItem(
    `__iAGSHFAW${import.meta.env.VITE_ENVIRONMENT}`,
  );
  const removeUser = () => {
    localStorage.removeItem(`__iAGSHFAW${import.meta.env.VITE_ENVIRONMENT}`);
  };
  return { createUser, removeUser, isLoggedIn };
};

export default useUserOperations;
