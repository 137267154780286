enum PAGE_URLS {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  HOME = 'home',
  NEW_OFFER = 'create-new-offer',
  REPORTS = 'reports',
  SESSION_EXPIRED = 'errors/401',
  FORBIDDEN = 'errors/403',
  NOT_FOUND = 'errors/404',
  INTERNAL_ERROR = 'errors/500',
  EDIT_OFFER = 'edit-offer',
}

export default PAGE_URLS;

export enum FORGOT_PASSWORD_PAGES {
  RESET_PASSWORD = 'reset_password',
  VERIFY_EMAIL = 'verify_email',
}

export enum OFFER_PAGES {
  NEW_TOP_UP = 'top-up',
}
