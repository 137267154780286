import styles from './LogoHeader.module.scss';
const LogoHeader = () => {
  return (
    <div className={styles['logo-header']}>
      <img
        className={styles['logo-header__logo']}
        src="/assets/plus-offer-logo.png"
        alt="YOUgotagift"
      />
    </div>
  );
};

export default LogoHeader;
